import React, { Component } from "react";
import '../assets/css/xmas.css';

class Xmas extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="bg-xmas">
                    <picture>
                        <source
                            srcSet="/assets/images/bg-xmas@3x.webp"
                            type="image/webp"
                            media="(min-width: 1600px)" />
                        <source
                            srcSet="/assets/images/bg-xmas@2x.webp"
                            type="image/webp"
                            media="(min-width: 768px)" />
                        <source
                            srcSet="/assets/images/bg-xmas@1x.webp"
                            type="image/webp"
                            media="(min-width: 320px)" />

                        <source
                            srcSet="/assets/images/bg-xmas@3x-min.jpg"
                            type="image/jpeg"
                            media="(min-width: 1600px)" />
                        <source
                            srcSet="/assets/images/bg-xmas@2x-min.jpg"
                            type="image/jpeg"
                            media="(min-width: 768px)" />
                        <source
                            srcSet="/assets/images/bg-xmas@1x-min.jpg"
                            type="image/jpeg"
                            media="(min-width: 320px)" />

                        <img src="/assets/images/bg-xmas@1x-min.jpg" alt="Xmas 8 bit" tittle="Xmas 8 bit" />
                    </picture>
                </div>

                <ul className="lightrope">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </React.Fragment>
        );
    }
}

export default Xmas;