import React, { Component } from "react";

class Experience extends Component {
    render() {
        return (
            <div className="nes-container with-title experience-list">
                <div className="title">
                    <div><i className="nes-icon trophy"></i></div>
                    <div>
                        <h2>Experience</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-xs-12">
                        <picture>
                            <source type="image/webp" srcSet="/assets/images/buzzwoo.webp" />
                            <source type="image/jpeg" srcSet="/assets/images/buzzwoo.jpg" />
                            <img src="/assets/images/buzzwoo.jpg" alt="BUZZWOO! GmbH &amp; Co. KG"
                                title="BUZZWOO! GmbH &amp; Co. KG" loading="lazy" />
                        </picture>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                        <h3>BUZZWOO! GmbH &amp; Co. KG</h3>
                        <p>Full-time · 5 yrs</p>
                        <ul>
                            <li>
                                <p>Senior Back End Developer</p>
                                <ul>
                                    <li><h6>Apr 2022 - Present · 2 yrs 9 mos</h6></li>
                                </ul>
                            </li>
                            <li>
                                <p>Back End Developer</p>
                                <ul>
                                    <li><h6>Jan 2020 - Mar 2022 · 2 yrs 3 mos</h6></li>
                                </ul>
                            </li>
                        </ul>
                        <small>
                            Wordpress Developer, PHP Developer, Back End Developer, Shopify, SEO
                        </small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-xs-12">
                        <picture>
                            <source type="image/webp" srcSet="/assets/images/one2crowd.webp" />
                            <source type="image/png" srcSet="/assets/images/one2crowd.png" />
                            <img src="/assets/images/one2crowd.png" alt="one2crowd Co.,Ltd"
                                title="one2crowd Co.,Ltd" loading="lazy" />
                        </picture>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                        <h3>one2crowd Co.,Ltd</h3>
                        <p>Full Stack Developer (Full-time)</p>
                        <h6>May 2014 - Nov 2019 · 5 yrs 7 mos</h6>
                        <small>
                            PHP Developer, Front-End Developer, Wordpress Developer, Ruby on Rails, Symfony
                        </small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-xs-12">
                        <picture>
                            <source type="image/webp" srcSet="/assets/images/siam-webdesign.webp" />
                            <source type="image/png" srcSet="/assets/images/siam-webdesign.png" />
                            <img src="/assets/images/siam-webdesign.png" alt="Siam Webdesign"
                                title="Siam Webdesign" loading="lazy" />
                        </picture>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                        <h3>Siam Webdesign</h3>
                        <p>Full Stack Developer (Freelance)</p>
                        <h6>Apr 2014 - Jan 2020 · 5 yrs 10 mos</h6>
                        <small>
                            Full Stack Developer, PHP Developer, Front-End Developer, Wordpress Developer, Codeigniter
                        </small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-xs-12">
                        <picture>
                            <source type="image/webp" srcSet="/assets/images/chiangmai-webdesign.webp" />
                            <source type="image/png" srcSet="/assets/images/chiangmai-webdesign.png" />
                            <img src="/assets/images/chiangmai-webdesign.png" alt="Chiang Mai Webdesign"
                                title="Chiang Mai Webdesign" loading="lazy" />
                        </picture>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                        <h3>Chiang Mai Webdesign</h3>
                        <p>Senior Web Developer (Full-time)</p>
                        <h6>Nov 2012 - Mar 2014 · 1 yr 5 mos</h6>
                        <small>
                            Full-Stack Development · PHP · CodeIgniter
                        </small>
                    </div>
                </div>
            </div>
        );
    }
}

export default Experience;